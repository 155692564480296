import {
  Component,
  EventEmitter,
  Inject,
  LOCALE_ID,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ModalComponent, ToastService, WindowService } from '@yoimo/joymo-ui';
import {
  AccessControlService,
  AuthService,
  LoggingService,
  PlatformService,
  ScopeService,
  TicketsService,
  TicketOptionsService,
} from '@core/services';
import { ChannelWithDocId } from '@yoimo/client-sdk/channels';
import {
  BillingPeriodicity,
  SoldTicket,
  Sport80PlanProvider,
  Ticket,
} from '@yoimo/interfaces';
import { VideoWithDocId, getVideo } from '@yoimo/client-sdk/videos';
import { EventWithDocId, getTickets, getEvent } from '@yoimo/client-sdk/events';
import {
  getPlan,
  PlanWithDocId,
  shouldShowTrialForPlan,
} from '@yoimo/client-sdk/subscriptions';
import {
  PlanOption,
  TicketOption,
  Option,
  AdditionalPaymentConfig,
  TicketOptionState,
} from './ticket-options-types';
import { planToPlanOption, ticketToTicketOption } from './data-transformers';
import {
  combineLatest,
  firstValueFrom,
  map,
  Observable,
  of,
  ReplaySubject,
  Subject,
  switchMap,
  take,
  takeUntil,
  tap,
  withLatestFrom,
  filter,
  EMPTY,
} from 'rxjs';
import { getSoldTickets, getUserInfo$ } from '@yoimo/client-sdk/users';
import {
  isPlanAvailableForPurchase,
  isSingleVideoTicket,
  isSoldTicketStillValid,
} from '@yoimo/client-sdk/business-logic';
import { Firestore } from '@angular/fire/firestore';
import { WithDocId } from '@yoimo/client-sdk/base';
import { Router, NavigationStart } from '@angular/router';
import {
  getSport80Settings$,
  getUserSport80Account,
} from '../profile/sport80/sport80.utils';
import { User } from '@angular/fire/auth';
import { ResolvedOptionsListLink } from '@yoimo/client-sdk/pages';

type Step = 'TICKET_SELECT' | 'HANDLE_PAYMENT';

/**
 * Display and manage the available ticket options based on the current use case (subscriptions/events/videos).
 */
@Component({
  selector: 'joymo-ticket-options',
  templateUrl: './ticket-options.component.html',
  styleUrls: ['./ticket-options.component.scss'],
  host: { '[hidden]': 'isServer' },
})
export class TicketOptionsComponent implements OnInit, OnDestroy {
  @ViewChild(ModalComponent) yoModal?: ModalComponent;
  @Output() onPaymentResult = new EventEmitter<'success' | 'failure'>();
  isLockedToSingleTicket?: boolean;
  isSubscriptionPaused?: boolean;
  videoId?: string;

  readonly channel$ = this.scopeService.listenToChannel();
  readonly paymentConfig$ = new ReplaySubject<AdditionalPaymentConfig>(1);

  eventId?: string;
  processStep: Step = 'TICKET_SELECT';
  selectedOption?: TicketOption | PlanOption;
  homepageUrl = '';
  dialogTitle?: string;
  private _subscriptionOptionStream = new ReplaySubject<PlanOption[]>(1);
  readonly subscriptionOptions$: Observable<PlanOption[]> =
    this._subscriptionOptionStream.asObservable();
  private _ticketOptionStream = new ReplaySubject<TicketOption[]>(1);
  readonly ticketOptions$: Observable<TicketOption[]> =
    this._ticketOptionStream.asObservable();

  private readonly destroy$ = new Subject<void>();
  readonly isServer: boolean;
  readonly stepTitles: Record<Step, string> = {
    HANDLE_PAYMENT: $localize`:@@ticketOptionsPurchaseStep:Purchase`,
    TICKET_SELECT: $localize`:@@ticketOptionsTicketSelectStep:Ticket options`,
  };
  readonly sport80Settings$: Observable<Sport80PlanProvider | undefined>;
  readonly canLinkSport80$: Observable<boolean>;
  readonly user$: Observable<User | null>;

  readonly ticketUnavailableReason: Record<TicketOptionState, string> = {
    EXPIRED: $localize`:@@ticketOptionsExpiredTicket:Purchase isn't available for expired tickets`,
    FUTURE_TICKET: $localize`:@@ticketOptionFutureTicket: Purchase isn't available yet. Coming soon`,
    UNAVAILABLE: $localize`:@@ticketOptionUnavailableMessage: Purchase not available`,
    NO_PRICE: $localize`:@@ticketOptionNoPrice: Ticket price undefined`,
    AVAILABLE: '',
  };

  constructor(
    private scopeService: ScopeService,
    @Inject(LOCALE_ID) public locale: string,
    private windowService: WindowService,
    private authService: AuthService,
    private toastService: ToastService,
    private accessControlService: AccessControlService,
    private ticketsService: TicketsService,
    private platformService: PlatformService,
    private fs: Firestore,
    private loggingService: LoggingService,
    private router: Router,
    private ticketOptionsService: TicketOptionsService
  ) {
    this.isServer = this.platformService.isServer();
    this.sport80Settings$ = this.getSport80$();
    this.canLinkSport80$ = this.checkIfUserCanLinkSport80$();
    this.user$ = this.authService.user$;
  }

  ngOnInit(): void {
    if (this.isServer) return;

    this.authService.forcedDisconnect$
      .pipe(takeUntil(this.destroy$))
      .subscribe((reason) => {
        this.yoModal?.close();
        this.router.navigate([this.scopeService.getHomepageUrl()]);
      });

    // Ensure modal is closed when the route changes.
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationStart),
        tap((e) => {
          this.yoModal?.close();
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  reset() {
    this.isLockedToSingleTicket = false;
    this.isSubscriptionPaused = false;
    this.videoId = undefined;
    this.eventId = undefined;
    this.processStep = 'TICKET_SELECT';
    this.selectedOption = undefined;
    this._subscriptionOptionStream.next([]);
    this._ticketOptionStream.next([]);
    this.homepageUrl = this.scopeService.getHomepageUrl();
    this.dialogTitle = undefined;
  }

  onTicketActionClick(channel: ChannelWithDocId, option: TicketOption) {
    if (option.isBoughtByUser) {
      this.router.navigate([this.homepageUrl, 'profile', 'tickets']);
      return;
    }
    this.onOptionSelected(channel, option);
  }

  async onOptionSelected(
    channel: ChannelWithDocId,
    option: Option,
    periodicity?: BillingPeriodicity
  ): Promise<void> {
    await firstValueFrom(
      this.requireUserOrRedirect$(
        this.ticketOptionsService.getOptionId(option, periodicity)
      )
    );

    this.processStep = 'HANDLE_PAYMENT';
    if (option.type === 'TICKET') {
      this.eventId = option.eventId;
      this.videoId = option.videoId;
      this.selectedOption = option;
      return;
    }

    const plan = planToPlanOption(
      channel,
      option.planData,
      this.locale,
      // We already know if the user has a trial
      option.allowTrial
    );
    plan.selectedPeriodicity =
      periodicity ||
      plan.cardDetails.planPrices.find((pp) => pp.highlighted)?.periodicity ||
      plan.cardDetails.planPrices[0].periodicity;
    this.selectedOption = plan;
  }

  handleActionResult(result: 'cancel' | 'success' | 'failure'): Promise<void> {
    if (result === 'cancel') {
      this.processStep = 'TICKET_SELECT';
      return Promise.resolve();
    }
    return firstValueFrom(
      this.channel$.pipe(
        map((channel) => {
          // Trigger Access Control Refresh
          this.accessControlService.refreshChannelEntry(channel, this.eventId);
          this.onPaymentResult.next(result);
          return this.yoModal?.close();
        })
      )
    );
  }

  goToAuthenticationUrl(channel: ChannelWithDocId) {
    const authUrl = this.authService.getAuthenticationUrl(
      channel.docId,
      channel.slug,
      this.windowService.document.location.href
    );
    this.windowService.document.location.assign(authUrl);
  }

  //  TODO: handle Plan unavailable states
  openOptionsList(
    sections: ResolvedOptionsListLink['sections'],
    dialogTitle?: string,
    isSubscriptionPaused?: boolean
  ): void {
    this.isSubscriptionPaused = isSubscriptionPaused;
    if (!sections.length) {
      throw new Error('No sections configured');
    }
    this.selectedOption = undefined;
    this.channel$
      .pipe(
        switchMap((channel) => {
          // TODO: handle multiple sections
          const section = this.getSectionOption(sections[0]);
          return combineLatest([
            of(section.title),
            this.getTicketOptionsForTickets$(channel, section.tickets),
            this.getPlanOptionsForPlans$(channel, section.plans),
          ]);
        }),
        take(1)
      )
      .subscribe(([title, ticketOptions, planOptions]) => {
        // TODO: use section title when multiple sections available
        this.dialogTitle = dialogTitle;
        this._subscriptionOptionStream.next(planOptions);
        this._ticketOptionStream.next(ticketOptions);
        this.yoModal?.open();
      });
  }

  getSectionOption(section: ResolvedOptionsListLink['sections'][0]): {
    title: string;
    plans: PlanWithDocId[];
    tickets: { event: EventWithDocId; ticket: Ticket }[];
  } {
    const options = section.products.reduce(
      (acc, currentValue) => {
        const { plans, ticketsAndEvents } = acc;
        'ticket' in currentValue
          ? ticketsAndEvents.push(currentValue)
          : plans.push(currentValue.plan);
        return { plans, ticketsAndEvents };
      },
      {
        plans: [] as PlanWithDocId[],
        ticketsAndEvents: [] as { event: EventWithDocId; ticket: Ticket }[],
      }
    );
    return {
      title: section.title,
      plans: options.plans,
      tickets: options.ticketsAndEvents,
    };
  }

  getPlanOptionsForPlans$(
    channel: ChannelWithDocId,
    plans: PlanWithDocId[]
  ): Observable<PlanOption[]> {
    if (!plans.length) {
      return of([]);
    }
    const user = this.authService.auth.currentUser;
    const plans$ = user
      ? combineLatest(
          plans.map((p) =>
            shouldShowTrialForPlan(
              this.fs,
              channel.docId,
              user?.uid,
              p.docId
            ).pipe(map((trial) => ({ plan: p, trial })))
          )
        )
      : of(plans.map((p) => ({ trial: true, plan: p })));

    return plans$.pipe(
      map((plans) => {
        return (plans || []).flatMap(({ plan, trial }) =>
          planToPlanOption(channel, plan, this.locale, trial)
        );
      })
    );
  }

  openPlan(planId: string, periodicity?: BillingPeriodicity): void {
    this.isLockedToSingleTicket = true;

    this.requireUserOrRedirect$(
      this.ticketOptionsService.getPlanOptionId(planId, periodicity)
    )
      .pipe(
        withLatestFrom(this.channel$),
        switchMap(([user, channel]) => {
          return getPlan(this.fs, channel.docId, planId).pipe(
            map((plan) => [channel, plan, user] as const)
          );
        }),
        switchMap(([channel, plan, user]) => {
          if (!plan || !isPlanAvailableForPurchase(plan)) {
            this.toastService.open(
              $localize`:@@ticketOptionsPlanUnavailable: Plan is not available`,
              { type: 'error' }
            );
            this.onPaymentResult.emit('failure');
            throw new Error('Plan is not available:' + planId);
          }
          return this.accessControlService
            .doesUserSubscriptionsCoverPlan(channel, user, plan)
            .pipe(
              map((planIsCovered) => [planIsCovered, plan, channel] as const)
            );
        }),
        switchMap(([planIsCovered, plan, channel]) => {
          if (planIsCovered) {
            this.toastService.open(
              $localize`:@@ticketOptionsPlanCoveredWithUsersSubscription: You have already subscribed`,
              { type: 'info' }
            );
            return EMPTY;
          }
          return this.getPlanOptionsForPlans$(channel, [plan]).pipe(
            map((option) => [option, channel] as const)
          );
        }),
        take(1)
      )
      .subscribe(([option, channel]) => {
        if (!option) {
          return;
        }
        this.onOptionSelected(channel, option[0], periodicity);
        this.yoModal?.open();
      });
  }

  openTicket(ticketId: string, eventId: string, videoId?: string): void {
    this.isLockedToSingleTicket = true;

    this.requireUserOrRedirect$(
      this.ticketOptionsService.getTicketOptionId(ticketId, eventId, videoId)
    )
      .pipe(
        withLatestFrom(this.channel$),
        switchMap(([_user, channel]) =>
          combineLatest([
            of(channel),
            getEvent(this.fs, channel.docId, eventId),
            videoId ? getVideo(this.fs, videoId, channel.docId) : of(undefined),
            this.getUserSoldTickets$(),
          ])
        ),
        switchMap(([channel, event, video, soldTickets]) => {
          if (!event) {
            throw new Error(`Event not found: ${eventId}`);
          }
          if (videoId && !video) {
            throw new Error(`Video not found: ${videoId}`);
          }

          const ticket = getTickets(event, {
            includeInvalid: true,
            includeTicketsWithLimitedRedemptions: true,
            video,
          }).find((t) => t.id === ticketId);
          if (!ticket) {
            throw new Error(`Ticket not found: ${ticketId}`);
          }
          if (
            video &&
            isSingleVideoTicket(ticket) &&
            soldTickets.find((t) => t.videoIdsAccessed.includes(video.docId))
          ) {
            LoggingService.setBreadcrumb('Ticket already bought', 'PAYMENTS', {
              ticketId: ticket.id,
              videoId: video.docId,
            });
            return EMPTY;
          }

          if (soldTickets.find((t) => t.ticketId === ticket.id)) {
            LoggingService.logError(
              `Ticket already bought by user: ${ticket.id}`
            );
            return EMPTY;
          }

          const ticketOption = ticketToTicketOption(
            channel,
            ticket,
            event,
            video,
            this.locale,
            this.homepageUrl,
            false
          );
          return combineLatest([
            of(channel),
            of(ticketOption),
            this.accessControlService.isUserSubscribedToChannel$(
              channel,
              event
            ),
          ]);
        }),
        take(1)
      )
      .subscribe(([channel, ticketOption, isUserSubscribed]) => {
        if (isUserSubscribed) {
          return;
        }
        this.onOptionSelected(channel, ticketOption);
        this.yoModal?.open();
      });
  }

  private getTicketOptionsForTickets$(
    channel: ChannelWithDocId,
    tickets: { ticket: Ticket; event: EventWithDocId; video?: VideoWithDocId }[]
  ): Observable<TicketOption[]> {
    return this.getUserSoldTickets$().pipe(
      map((userTickets) =>
        tickets.map((obj) =>
          this.transformTicketToTicketOption(
            channel,
            obj.ticket,
            obj.event,
            userTickets,
            obj.video
          )
        )
      )
    );
  }

  private transformTicketToTicketOption(
    channel: ChannelWithDocId,
    ticket: Ticket,
    event: EventWithDocId,
    userTickets: WithDocId<SoldTicket>[],
    video?: VideoWithDocId
  ): TicketOption {
    // Has the user bought this ticket
    const soldTicket = userTickets.find(
      (st) => st.eventId === event.docId && st.ticketId === ticket.id
    );
    // Is it still valid
    const validSoldTicket =
      soldTicket &&
      isSoldTicketStillValid(soldTicket, event, ticket.scope, video?.docId);

    return ticketToTicketOption(
      channel,
      ticket,
      event,
      video,
      this.locale,
      this.scopeService.getHomepageUrl(),
      validSoldTicket
    );
  }

  private requireUserOrRedirect$(optionId: string): Observable<User> {
    return combineLatest([this.user$, this.channel$]).pipe(
      switchMap(([user, channel]) => {
        if (!user) {
          const preSelectionUrl =
            this.ticketsService.getPreSelectionUrl(optionId);
          const authUrl = this.authService.getAuthenticationUrl(
            channel.docId,
            channel.slug,
            preSelectionUrl
          );
          this.windowService.document.location.assign(authUrl);
          return EMPTY;
        }
        return of(user);
      }),
      take(1)
    );
  }

  private getUserSoldTickets$(): Observable<WithDocId<SoldTicket>[]> {
    const user = this.authService.auth.currentUser;
    return !user
      ? of([])
      : this.scopeService.scope$.pipe(
          switchMap((scope) =>
            getSoldTickets(this.fs, user.uid, {
              scope: scope.scope,
              scopeId: scope.scopeId,
              pager: of('init'),
            })
          ),
          map((q) => q.data)
        );
  }

  private getSport80$(): Observable<Sport80PlanProvider | undefined> {
    return combineLatest([this.authService.user$, this.channel$]).pipe(
      switchMap(([user, channel]) =>
        getSport80Settings$(this.fs, channel.docId, user?.uid || '')
      )
    );
  }

  private checkIfUserCanLinkSport80$(): Observable<boolean> {
    return this.authService.user$.pipe(
      switchMap((user) => {
        if (!user) {
          return of(undefined);
        }
        return getUserInfo$(user.uid, this.fs);
      }),
      withLatestFrom(this.channel$),
      map(([user, channel]) => {
        if (!user) {
          return true;
        }
        return !getUserSport80Account(channel.docId, user);
      }),
      map(Boolean)
    );
  }
}
